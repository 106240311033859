.tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.box {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.08);
}

.tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.box {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.08);
}

.tag {
  border: 1px solid #186f2d;
  background: white;
  border-radius: 30px;
  padding: 6px 12px;
  font-size: 0.85em;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.tag--active {
  background: #186f2d;
  color: #fefefe;
}
.tag--not-interactive {
  cursor: default;
}
.tag:not(.tag--not-interactive):hover, .tag:not(.tag--not-interactive):focus {
  box-shadow: 0 0 0 2px #186f2d inset;
  outline: none;
}
.tag--cases-map {
  height: 33px;
}
.tag--timeline {
  height: 32px;
  font-size: 14px;
  border-color: currentColor;
  background-color: currentColor;
}
.tag--timeline > strong {
  color: #fefefe;
}

.tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.box {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.08);
}

.box {
  background: white;
  padding: 30px var(--gutter);
  border-radius: 10px;
}
@media print {
  .box {
    page-break-inside: avoid;
    break-inside: avoid;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
  }
}
@media screen and (min-width: 48em) {
  .box--tablet-big-gutter {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.box--center {
  max-width: 1326px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}
@media screen and (min-width: 85.375em) {
  .box--center {
    margin-left: auto;
    margin-right: auto;
  }
}
.box--center-narrow {
  max-width: 800px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}
@media screen and (min-width: 52.5em) {
  .box--center-narrow {
    margin-left: auto;
    margin-right: auto;
  }
}
.box--center-xxl {
  max-width: 1600px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}
@media screen and (min-width: 102.5em) {
  .box--center-xxl {
    margin-left: auto;
    margin-right: auto;
  }
}
.box__header {
  margin-bottom: 3vh;
  text-align: center;
}
.box__lead {
  max-width: 600px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
  margin-bottom: 2.5vh;
}
@media screen and (min-width: 40em) {
  .box__lead {
    margin-left: auto;
    margin-right: auto;
  }
}
.box__subtitle {
  font-weight: 500;
  font-size: calc(1.1rem + .2vw);
}
.box__info {
  display: block;
  margin-top: 15px;
  font-size: 0.8rem;
  color: #838388;
}
.box__info--regular-size {
  font-size: 1rem;
}
.box--video > .video {
  width: calc(100% + 20px);
  margin: -30px 0 2vh -10px;
  overflow: hidden;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.box--video > .video:before {
  content: "";
  position: absolute;
}
@media screen and (min-width: 64em) {
  .box--video > .video {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.box .box__image-container {
  background: rgba(0, 0, 0, 0.1);
  margin: -30px calc(var(--gutter) * -1) 0.8em calc(var(--gutter) * -1);
  overflow: hidden;
  border-radius: 10px;
}

a.box:hover, a.box:focus {
  text-decoration: none;
}
a.box:hover .box__subtitle, a.box:focus .box__subtitle {
  text-decoration: underline;
}

.tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.box {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.08);
}

.virtous-circle {
  width: 270px;
  height: 280px;
  background-size: 8400px 280px;
  background-repeat: no-repeat;
  background-position: 0 -1px;
  animation: play-infographic 7s steps(31, end) infinite;
}
.virtous-circle.lazy-loading-loaded {
  background-image: url("virtous-circle-animation-sprite-min.jpg");
}
@media screen and (min-width: 37.5em) {
  .virtous-circle {
    width: 385px;
    height: 400px;
    background-size: 12000px 400px;
    animation: play-infographic-large 7s steps(31, end) infinite;
  }
}
@keyframes play-infographic {
  100% {
    background-position: -8400px -1px;
  }
}
@keyframes play-infographic-large {
  100% {
    background-position: -12000px -1px;
  }
}

@media screen and (min-width: 48em) {
  .vision {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.vision__header {
  text-align: center;
  margin-bottom: 3vh;
}
@media screen and (min-width: 48em) {
  .vision__header {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .vision__intro {
    flex: 0 0 calc(40% - 10px);
    max-width: calc(40% - 10px);
  }
}
@media screen and (min-width: 64em) {
  .vision__intro {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.vision__intro .main-copy p {
  line-height: 1.8;
}
.vision__graphic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6vh;
}
@media screen and (min-width: 48em) {
  .vision__graphic {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 64em) {
  .vision__graphic {
    flex: 0 0 calc(60% - 10px);
    max-width: calc(60% - 10px);
  }
}
.vision__highlight {
  font-size: 86%;
  border-radius: 6px;
  padding: 0.2em 0.4em;
  font-weight: 500;
  white-space: nowrap;
}
.vision__highlight--activists {
  background: #443f46;
  color: #fefefe;
}
.vision__highlight--citizens {
  background: #e5c147;
}
.vision__highlight--government {
  background: #e58b97;
  color: #fefefe;
}
.vision__highlight--academia {
  background: #4e89b1;
  color: #fefefe;
}
.vision__highlight--petition {
  background: #6b5fdd;
  color: #fefefe;
}
.vision__case {
  margin-bottom: 4vh;
  max-width: 620px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 640px) {
  .vision__case {
    background: white;
    padding: 30px var(--gutter);
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.08);
  }
}
.vision__case > .video {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-bottom: 2vh;
}
@media screen and (min-width: 640px) {
  .vision__case > .video {
    margin-top: -30px;
    overflow: hidden;
    border-radius: 10px;
  }
  .vision__case > .video:before {
    content: "";
    position: absolute;
  }
}
@media screen and (min-width: 64em) {
  .vision__case > .video {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.vision__case:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .vision__case {
    flex: 0 0 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-bottom: -90px !important;
  }
}
@media screen and (min-width: 64em) {
  .vision__case {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media screen and (min-width: 48em) {
  .vision-offset-desktop {
    padding-bottom: 60px;
  }
}