@use "z-indexes" as z;
@use "colors" as c;
@use "typography" as t;
@use "spacings" as sp;
@use "functions" as f;
@use "decorations" as d;

%text-colored-link {
  color: c.$base-text-color;
  text-decoration: none;
}

%header-font {
  font-family: t.$header-font;
  font-weight: 700;
}

%backdrop {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: c.$backdrop-grey;
  z-index: z.$z-lowest;
}

%grounge-mask {
  content: '';
  position: absolute;
  z-index: z.$z-low;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

%hero-text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
}

%hero-box-shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, .2), 0 0 26px rgba(0, 0, 0, .1);
}

%section-v-divider {
  margin-top: 14vh;
}

%v-gutter-inside-section {
  margin-bottom: sp.$section-items-v-gutter;
}

%picture-background {
  background-color: c.$backdrop-grey;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

%side-gutters {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

%smooth-scroll {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-scroll-snap-type: mandatory;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; // remove the scrollbar in FireFox
}

%slider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  // add an dummy element that pushes the last card away
  // from the window's edge when scrolled to the fullest
  // padding doesn't work on a scroll-plane, and with margin
  // we lose the effect of the container sticking
  // to the window's edge
  &:after {
    content: '';
    height: sp.$gutter; // has to have height to display it
    flex: 0 0 1px; // just to give it layout, the last elements margin can now work
  }

}

%single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%button-base {
  cursor: pointer;
  font-family: t.$copy-font;
  -webkit-appearance: none;
  transition: all .3s;
  text-align: center;
  font-size: 1rem;
}

%box-shadow {
  box-shadow: 0 0 d.$box-shadow-size rgba(0, 0, 0, .1),
  0 0 6px rgba(0, 0, 0, .08);
}