@use "../../../scss/config/breakpoints" as bp;
@use "../../../scss/config/mixins" as m;
@use "../../../scss/config/sizes" as s;
@use "../../../scss/config/spacings" as sp;
@use "../../../scss/config/decorations" as d;
@use "../../../scss/config/colors" as c;
@import "../../../scss/config/silent-classes";
@import "virtous-circle/virtous-circle";

@mixin column-desktop($width) {
  @media screen and (min-width: bp.$desktop) {
    @include m.flex-column-with-offset($width, sp.$gutter);
  }
}

@mixin box-shadow-for-desktop() {
  @extend %box-shadow;
}

.vision {
  @media screen and (min-width: bp.$tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__header {
    text-align: center;
    margin-bottom: sp.$v-gutter-near;

    @media screen and (min-width: bp.$tablet) {
      @include m.flex-column();
    }

  }

  &__intro {
    @include column-desktop(40%);

    @media screen and (min-width: bp.$desktop) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .main-copy p {
      line-height: 1.8;
    }

  }

  &__graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: sp.$v-gutter-far;

    @media screen and (min-width: bp.$tablet) {
      margin-left: auto;
      margin-right: auto;
    }

    @include column-desktop(60%);

  }

  &__highlight {
    font-size: 86%;
    border-radius: 6px;
    padding: .2em .4em;
    font-weight: 500;
    white-space: nowrap;

    &--activists {
      background: #443f46;
      color: c.$almost-white;
    }

    &--citizens {
      background: #e5c147;
    }

    &--government {
      background: #e58b97;
      color: c.$almost-white;
    }

    &--academia {
      background: #4e89b1;
      color: c.$almost-white;
    }

    &--petition {
      background: #6b5fdd;
      color: c.$almost-white;
    }

  }

  &__case {
    margin-bottom: 4vh;
    max-width: s.$yt-thumbnail-standard-w - sp.$gutter * 2;
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: s.$yt-thumbnail-standard-w) {
      background: white;
      padding: sp.$grid-v-gutter var(--gutter);
      border-radius: d.$box-radius;
      box-shadow: 0 0 d.$box-shadow-size rgba(0, 0, 0, .1),
                  0 0 6px rgba(0, 0, 0, .08);
    }

    > .video {
      width: calc(100% + #{sp.$gutter * 2});
      margin-left: -#{sp.$gutter};
      margin-bottom: 2vh;

      @media screen and (min-width: s.$yt-thumbnail-standard-w) {
        margin-top: -#{sp.$grid-v-gutter};
        overflow: hidden;
        border-radius: d.$box-radius;

        &:before {
          content: '';
          position: absolute;
        }

      }

      @media screen and (min-width: bp.$desktop) {
        width: calc(100% + #{sp.$gutter-desktop * 2});
        margin-left: -#{sp.$gutter-desktop};
      }

    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: bp.$tablet) {
      @include m.flex-column-with-offset(50%, #{sp.$gutter / 2});
      margin-bottom: -#{sp.$grid-v-gutter * 3} !important;
    }

    @media screen and (min-width: bp.$desktop) {
      @include m.flex-column-with-offset(50%, sp.$gutter);
    }

  }

}

.vision-offset-desktop {
  @media screen and (min-width: bp.$tablet) {
    padding-bottom: sp.$grid-v-gutter * 2;
  }
}
