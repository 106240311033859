@use "functions" as f;
@use "spacings" as sp;
@use "decorations" as d;
@use "colors" as c;

@mixin scroll-fader($color: #fff, $width: 30px) {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $width;
  pointer-events: none;
  background: linear-gradient(
                  to right, rgba($color, 0) 0%,
                  rgba($color, 1) 100%);
}

@mixin flex-column($width: 100%) {
  flex: 0 0 $width;
  max-width: $width;
}

@mixin flex-column-with-offset($width, $offset) {
  flex: 0 0 calc(#{$width} - #{$offset});
  max-width: calc(#{$width} - #{$offset});
}

@mixin pillar-box($max-width) {
  max-width: $max-width;
  margin-left: var(--gutter);
  margin-right: var(--gutter);

  @media screen and (min-width: f.px-to-em($max-width) + f.px-to-em(sp.$gutter-desktop) * 2) {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin custom-scrollbar {

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .4);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .1);
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, .1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: c.$secondary-text-color;
  }

}
