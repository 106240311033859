@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/colors" as c;
@use "../../scss/config/decorations" as d;
@use "../../scss/config/sizes" as s;
@use "../../scss/config/spacings" as sp;
@use "../../scss/config/mixins" as m;
@import "../../scss/config/silent-classes";

$map-height-mobile: 80vh;
$map-height: 60vh;

.cases-map {

  @media screen and (min-width: bp.$desktop) {
    display: grid;
    grid-template-columns: [first] 4fr [second] 6fr;
    // TODO: Figure out a more flexible layout - If text grows significantly then rows, don't adjust
    grid-template-rows: [title] 10% [intro] 20% [tags] 70%;
    grid-column-gap: var(--gutter);
  }

  @media screen and (min-width: s.$page-max-w) {
    grid-column-gap: sp.$gutter-desktop * 2;
  }

  @supports (grid-template-areas: unset) {
    @media screen and (min-width: bp.$desktop) {
      padding-right: 0 !important;
      height: $map-height;
    }
  }

  &__header.box__header {
    @media screen and (min-width: bp.$desktop) {
      grid-column-start: first;
      grid-row-start: title;
    }

    @supports (grid-template-areas: unset) {
      @media screen and (min-width: bp.$desktop) {
        text-align: left;
        margin-bottom: 0;
      }
    }

  }

  &__lead {
    @media screen and (min-width: bp.$desktop) {
      grid-column-start: first;
      grid-row-start: intro;
      max-width: none !important;
    }
  }

  &__navigation {
    width: calc(100% + #{sp.$gutter});
    height: s.$cases-map-tag-height;
    overflow: hidden;
    margin-bottom: 2vh;

    &:after {
      @include m.scroll-fader();

      @media screen and (min-width: bp.$tablet) {
        display: none;
      }

    }

    @media screen and (min-width: bp.$tablet) {
      height: auto;
      width: 100%;
      overflow: visible;
    }

    @media screen and (min-width: bp.$desktop) {
      margin-bottom: 0;
      grid-column-start: first;
      grid-row-start: tags;
    }

  }

  &__navigation-list {
    @extend %smooth-scroll;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: content-box;
    padding-bottom: 17px;

    @media screen and (min-width: bp.$tablet) {
      display: block;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 0;
      box-sizing: border-box;
      scrollbar-width: auto;
      @include m.custom-scrollbar();
    }

  }

  &__navigation-item {
    margin-right: 4vw;

    &:last-of-type {
      margin-right: 0;
      padding-right: sp.$gutter;

      // this is to keep tha last item away from the edge
      // when scrolled all the way
      @media screen and (min-width: bp.$tablet) {
        padding-right: 0;
      }
    }

    @media screen and (min-width: bp.$tablet) {
      display: inline-block;
      margin: 0 sp.$gutter-desktop (sp.$gutter-desktop - 5px) 0;
    }

  }

  &__map {
    width: calc(100% + #{sp.$gutter * 2});
    height: $map-height-mobile;
    margin-left: -#{sp.$gutter};
    margin-bottom: -#{sp.$grid-v-gutter};
    background: c.$base-text-color;
    border-radius: d.$box-radius;
    z-index: 1; // need to normalize this because the embedded Leaflet Map has a high z-index which makes it overlap the main header

    @supports (grid-template-areas: unset) {
      @media screen and (min-width: bp.$desktop) {
        width: 100%;
        height: calc(#{$map-height} + #{sp.$grid-v-gutter * 2});
        margin-left: 0;
        margin-top: -#{sp.$grid-v-gutter * 2};
        margin-bottom: 0;
      }
    }
  }

  // fix for Open Streetmap attribution DIV and copyright notice not showing in the bottom right corner
  .leaflet-control-container {
    height: 100%;
  }

}

.cases-map-offset-desktop {
  @supports (grid-template-areas: unset) {
    @media screen and (min-width: bp.$desktop) {
      padding-bottom: sp.$grid-v-gutter;
    }
  }
}
