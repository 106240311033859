$mobile-header-top-h: 60px;
$mobile-navigation-h: 36px;
$desktop-header-h: 60px;

$button-radius: 4px;
$page-max-w: 1326px;
$article-max-w: 800px;
$narrow-content-w: 1006px;
$xxl-w: 1600px;

$yt-thumbnail-standard-w: 640px;

$cases-map-tag-height: 33px; // has to match the width of the buttons - needs fixed height to hide OS scrollbar

$timeline-marker-label-h: 32px;