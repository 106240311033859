@use "../../scss/config/colors" as c;
@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/sizes" as s;
@use "../../scss/config/spacings" as sp;
@use "../../scss/config/decorations" as d;
@use "../../scss/config/mixins" as m;
@import "../../scss/config/silent-classes";

$logo-height: 32px;

.news {
  overflow: hidden;

  @media screen and (min-width: bp.$tablet) {
    overflow: visible;
    max-width: bp.$desktop;
    margin: 0 auto;
  }

  @media screen and (min-width: s.$xxl-w + sp.$gutter-desktop * 2) {
    max-width: s.$xxl-w;
    margin-left: auto;
    margin-right: auto;
  }

  &:after {
    z-index: 1;
    @include m.scroll-fader(c.$beige, 20px);

    @media screen and (min-width: bp.$tablet) {
      display: none;
    }

  }

  &__list {
    @extend %smooth-scroll;
    @extend %slider;
    padding: d.$box-shadow-size 0 d.$box-shadow-size sp.$gutter;

    @media screen and (min-width: bp.$tablet) {
      padding: 0 (sp.$gutter / 2);
      flex-wrap: wrap;
      justify-content: center;
      overflow: visible;

      &:after {
        display: none;
      }

    }

    @media screen and (min-width: bp.$desktop) {
      padding: 0 sp.$gutter;
    }

  }

  &__item {
    margin-right: sp.$gutter;
    @include m.flex-column-with-offset(92%, 8px);

    @media screen and (min-width: bp.$tablet) {
      margin: 0 0 sp.$grid-v-gutter 0;
    }

    > .box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    padding: 0 (sp.$gutter / 2);

    @media screen and (min-width: bp.$tablet) {
      @include m.flex-column(50%);
    }

    @media screen and (min-width: bp.$desktop) {
      padding: 0 sp.$gutter;
      @include m.flex-column(33.3333%);
    }

    @media screen and (min-width: s.$xxl-w + sp.$gutter-desktop * 2) {
      @include m.flex-column(25%);
    }

  }

  &__item--more {
    background: rgba(0, 0, 0, .1);
    border-radius: d.$box-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:
            0 0 16px rgba(0, 0, 0, .1) inset,
            0 0 6px rgba(0, 0, 0, .08) inset;

    @media screen and (min-width: bp.$tablet) {
      background: none;
      box-shadow: none;
      @include m.flex-column(100%);
    }

  }

  &-source {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;

    & .box__info {
      @extend %single-line-ellipsis;
      margin-top: 0;
    }

  }

}
